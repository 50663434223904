import { Fragment } from "react";
import SharedHead from "components/head";
import Router from "routes/router";
import OneTrust from "components/external/onetrust";
import GTagManager from "components/external/google-tag-manager";
import PaddleJS from "components/external/paddle";

const App = () => (
  <Fragment>
    {/* these will be in <head /> */}
    {import.meta.env.VITE_PUBLIC_NAME !== "production" && (
      <meta name="robots" content="noindex,follow" />
    )}

    {/* shared head defaults, goes to <head /> */}
    <SharedHead />

    {/* load paddle, goes to <head /> */}
    <PaddleJS />

    {/* load onetrust, goes to <head /> */}
    <OneTrust />

    {/* load gtm, goes to <head /> */}
    <GTagManager />

    {/* main thing, goes to <body /> */}
    <Router />
  </Fragment>
);

export default App;
