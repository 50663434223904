import ThemeProvider from "providers/theme";
import LinkLoginView from "./view";
import ConfigProvider from "providers/config";
import QuestionnaireProvider from "providers/questionnaire";
import { ControlQuestionnaireVersion } from "models/questionnaire";
import StateProvider from "providers/state";

const LinkLoginViewWrapper = () => {
  return (
    <ThemeProvider>
      <ConfigProvider>
        <StateProvider>
          <QuestionnaireProvider
            version={ControlQuestionnaireVersion}
            questionId={2}
          >
            <LinkLoginView />
          </QuestionnaireProvider>
        </StateProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default LinkLoginViewWrapper;
