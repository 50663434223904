import { FunctionComponent, useEffect } from "react";

const OneTrust: FunctionComponent = () => {
  useEffect(() => {
    var loadScript = document.createElement("script");
    loadScript.type = "text/javascript";
    loadScript.src = import.meta.env.VITE_PUBLIC_ONETRUST_URL;
    loadScript.setAttribute("data-document-language", "true");
    loadScript.setAttribute(
      "data-domain-script",
      import.meta.env.VITE_PUBLIC_ONETRUST_DOMAIN
    );
    document.head.appendChild(loadScript);
  }, []);

  return null;
};

export default OneTrust;
